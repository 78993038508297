h1.responsive-headline {
    font-size: 60px !important;
    line-height: 1.7 !important;
}
@media only screen and (max-width:540px){
    h1.responsive-headline {
        font-size: 30px !important;
        line-height: 1.5 !important;
    }
}
